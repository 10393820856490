const sections = document.querySelectorAll('.panel');
let current = 0;
let next = 0;
let direction = 'down';
let listening = true;
let isMobile =
  window.matchMedia('(max-width: 1200px)').matches ||
  window.matchMedia('(max-height: 640px)').matches;

// gsap.defaults({ ease: 'slow(0.5, 0.8)' });
console.log('common.js');
gsap.defaults({ ease: 'power1.out' });

if (!isMobile) {
  gsap.set(sections, { autoAlpha: 0 });

  // Only display the current and next sections
  if (sections[current]) {
    gsap.set(sections[current], { autoAlpha: 1 });
  }
}

if (isMobile) {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const sectionIndex = Array.from(sections).indexOf(entry.target);
      if (entry.isIntersecting && sectionIndex !== current) {
        // Trigger the animation for the section that is currently in view
        animateSection(sectionIndex);
      }
    });
  });

  sections.forEach((section) => {
    observer.observe(section);
  });
}

let animationQueue = Promise.resolve();

function queueAnimation(sectionIndex, reverse = false) {
  animationQueue = animationQueue.then(() =>
    animateSection(sectionIndex, reverse)
  );
}

function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

document.addEventListener(
  'wheel',
  debounce((e) => {
    if (!listening) return;
    direction = e.deltaY > 0 ? 'down' : 'up';
    if (!document.querySelector('body').classList.contains('no-scroll')) {
      handleDirection();
    }
  }, 150)
);

if (isMobile) {
  document.querySelector('body').classList.remove('correct-size');
} else {
  document.querySelector('body').classList.add('correct-size');
}

document.addEventListener('DOMContentLoaded', () => {
  let initialIsMobile = isMobile;

  window.addEventListener('resize', function () {
    isMobile =
      window.matchMedia('(max-width: 1200px)').matches ||
      window.matchMedia('(max-height: 640px)').matches;

    if (isMobile !== initialIsMobile) {
      location.reload();
    }

    if (isMobile) {
      document.querySelector('body').classList.remove('correct-size');
    } else {
      document.querySelector('body').classList.add('correct-size');
    }
  });
});
